import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form, Row, Col, Alert, Tabs, Tab } from "react-bootstrap";
import JSONGenerator from '../../../services/api/JSONGenerator'
import { postApi } from "../../../services/api/requestApi";
import LoadingIcon from "../../../components/atoms/LoadingIcon";

export default class Threshold extends React.Component {

    constructor (props) {
        super (props);
        this.state = {
            thresholdFile: null,
            thresholdSet: false,
            threshold: null,
            showModal: props.showModal,
            setShowModal: props.setShowModal,
            reload: props.reload,
            loading: true,
            error:false,
            error2:false
        };
        
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount = async () => {
        let url = '/admin/aois/threshold/get';
        let body = {
            aoi: this.props.aoi.aoi_name,
        }
        let response = await postApi(url, body);
        if(response.success === true) {
            let data = await response.data;

            if (data.exists == false) {
                let jsonData = {};
                jsonData.default={"hole":{"evi":{"min":"","max":"","medium":""},"ndvi":{"min":"","max":"","medium":""},"gndvi":{"min":"","max":"","medium":""},"smi":{"min":"","max":"","medium":""}}}
                jsonData.default.tee = {"evi":{"min":"","max":"","medium":""},"ndvi":{"min":"","max":"","medium":""},"gndvi":{"min":"","max":"","medium":""},"smi":{"min":"","max":"","medium":""}}
                jsonData.default.fairway = {"evi":{"min":"","max":"","medium":""},"ndvi":{"min":"","max":"","medium":""},"gndvi":{"min":"","max":"","medium":""},"smi":{"min":"","max":"","medium":""}}
                jsonData.default.green = {"evi":{"min":"","max":"","medium":""},"ndvi":{"min":"","max":"","medium":""},"gndvi":{"min":"","max":"","medium":""},"smi":{"min":"","max":"","medium":""}}

                
                

                for(let key in data.threshold.features) {
                    jsonData[data.threshold.features[key].properties.name]= {"holeId":data.threshold.features[key].properties.holeId, "type":data.threshold.features[key].properties.type,"value":{"evi":{"min":"0,2","max":"0,3","medium":""},"ndvi":{"min":"","max":"","medium":""},"gndvi":{"min":"","max":"","medium":""},"smi":{"min":"","max":"","medium":""}}}
                }
                
                this.setState({
                    threshold: jsonData
                })
                
            }else {
                this.setState({
                    threshold: data.threshold
                })
            }
            
           
        }else {
            this.setState({
                error: 'Couldnt load GeoJson. Please upload a GeoJson first.',
            });

        }

        this.setState({
            loading: false,
        });
    }

    handleClose = () => {
        this.state.setShowModal('Threshold', false);
        this.setState({
            alias: '',
            thresholdFile: null,
            layers: null,
            thresholdSet: false,
            threshold: null,
            name:''
        })
    }

    handleChange(e, hole, indice, x, isDefault) {
        let copyThreshold = this.state.threshold;
        if (!isDefault) {
            copyThreshold[hole].value[indice][x] = e.target.value;
            this.setState({
                threshold: copyThreshold
            })
        }else {
            copyThreshold["default"][hole][indice][x] = e.target.value;
            this.setState({
                threshold: copyThreshold
            })
        }
    }

    handleSubmit() {
        let submitError;
        let submitErrorMessage;
        for(let key in this.state.threshold["default"]) {
            for(let key2 in this.state.threshold["default"][key]) {
                for(let key3 in this.state.threshold["default"][key][key2]) {
                    console.log(typeof(this.state.threshold["default"][key][key2][key3]))
                    if(this.state.threshold["default"][key][key2][key3]=="") {
                        submitError = true;
                        submitErrorMessage =  "Missing Property in default "+[key]+" "+[key2]+" "+key3
                    }
                }
            }
        }

        if(submitError){
            this.setState({
                error2: submitErrorMessage,
            });
        }else {
            
        }
    }

    createList = () => {
        let result = [];
        let holes = [];

        for(let key in this.state.threshold) {
            if (key !== "default") {
                if(holes[this.state.threshold[key].holeId] == undefined){
                    holes[this.state.threshold[key].holeId] = [];
                    holes[this.state.threshold[key].holeId].push(key);
                }else {
                    holes[this.state.threshold[key].holeId].push(key);
                }
            }else {
                holes["default"]= []
                holes["default"].push("hole","tee","fairway","green")
            }
        }
        for (let key in holes) {
            let indices = [];
            for(let key2 in holes[key]){
                if(key !== "default") {
                    indices.push(<h5>{holes[key][key2]}</h5>);
                    for (let key3 in this.state.threshold[holes[key][key2]].value) {
                        indices.push(<span>{key3}</span>);
                        let columns = [];
                        for (let key4 in this.state.threshold[holes[key][key2]].value[key3]) {
                            
                            columns.push(<Form.Group as={Col} className="10">
                            <Form.Label>{key4}</Form.Label>
                                <Form.Control
                                required
                                type="text"
                                name="test"
                                value = {this.state.threshold[holes[key][key2]].value[key3][key4]}
                                onChange={(e) => this.handleChange(e,holes[key][key2],key3,key4,false)}
                                />
                            </Form.Group>)
                        }
                        indices.push(<Row className="mb-3">{columns}</Row>);
                    }
                }else {
                    indices.push(<h5>{holes[key][key2]}</h5>);
                    for (let key3 in this.state.threshold[key][holes[key][key2]]) { 
                        indices.push(<span>{key3}</span>);
                        let columns = [];
                        for (let key4 in this.state.threshold[key][holes[key][key2]][key3]) {
                            columns.push(<Form.Group as={Col} className="10">
                            <Form.Label>{key4}</Form.Label>
                                <Form.Control
                                required
                                type="text"
                                name="test"
                                value = {this.state.threshold[key][holes[key][key2]][key3][key4]}
                                onChange={(e) => this.handleChange(e,holes[key][key2],key3,key4,true)}
                                />
                            </Form.Group>)
                        }
                        indices.push(<Row className="mb-3">{columns}</Row>);
                    }
                }
            }
            result.push(
                <Tab eventKey={key} title={key}>
                    {indices}
                </Tab>
            );
        } 

        return result;
    }

    Threshold () {
        return (
            <>
                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Threshold</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {this.state.loading ? (
                        <LoadingIcon />
                        ) : this.state.error ? <Alert variant="danger" transition={false}>
                        {this.state.error}
                      </Alert> : (
                        <>
                            {this.state.error2 ? <Alert variant="danger" transition={false}>
                        {this.state.error2}
                      </Alert> :"" }
                            <Form>
                                <Tabs
                                    className="mb-3"
                                    fill
                                    justify
                                    defaultActiveKey="vis_params"
                                >
                                    {this.createList()}
                                </Tabs>
                            </Form>
                        </>
                    )}

                    </Modal.Body>
                    <Modal.Footer>
                    <button
                        className="btn btn-dark mr-3"
                        type="button"
                        onClick={() =>
                        this.handleSubmit()
                        }
                    >
                        Submit
                    </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    render () {
        return this.Threshold();
    }
}