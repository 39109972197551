import React, { useState } from "react";
import {
  AiFillPropertySafety,
  AiOutlineCaretLeft,
  AiOutlineCaretRight,
} from "react-icons/ai";
import {
  RiLayoutGridFill,
  RiFileDownloadLine,
  RiExchangeLine,
  RiCloseFill,
} from "react-icons/ri";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "./SidePanel.scss";
import { DateRangePicker } from "rsuite";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import "react-bootstrap-submenu/dist/index.css";
import { GEE_URL } from "../../../env";
import Chart from "../charts/Chart";
import Modal from "react-bootstrap/Modal";
import LoadingDots from "../../atoms/LoadingDots";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import { Translate, translate } from "react-i18nify";
import { format, formatRelative } from "date-fns";
import { Tab, Tabs } from "react-bootstrap";

export default function SidePanel(props) {
  const [sidePanelOpened, setSidePanelOpened] = useState(true);
  const [currentDates, setCurrentDates] = useState([new Date(), new Date()]);
  const [error, setError] = useState("");
  const [rangeSelected, setRangeSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpecialLayerSelected, setIsSpecialLayerSelected] = useState(false);
  const [initialize, setInitialize] = useState(true);
  const [availableDates, setAvailableDates] = useState({});
  const [subButtons, setSubButtons] = useState();
  const [geoDropdownValue, setGeoDropdownValue] = useState(
    <Translate value="sidepanel.show_all" />
  );
  const [subpartDropdownValue, setSubpartDropdownValue] = useState(
    translate("sidepanel.choose")
  );
  const [min, setMin] = useState({});
  const [max, setMax] = useState({});
  const [avg, setAvg] = useState({});
  const [showAbs, setShowAbs] = useState(false);

  let geometrics = {};
  let chartData = {};
  let activeChartData = {};
  let activeButtons = {};
  let layerButtons = [];

  const [showSpecialLayerButtons, setShowSpecialLayerButtons] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const toggleSidePanel = () => {
    if (sidePanelOpened) {
      setSidePanelOpened(false);
    } else {
      setSidePanelOpened(true);
    }
  };

  /**
   * Gets start view data from the node express server, formats it and saves it in the react state ,
   *
   * @param geo name of the geo gotten from the dropdown menu or the current geo
   * TODO: Need to catch specific errors. For example, if there are no images for the dates selected by the user
   *
   * @author Philip Sada
   * @modified_by Hagen Hoppenstedt
   */
  const getData = async (
    selectedGeo,
    subpart = null,
    reload,
    dates,
    range,
    bicubicSet,
    increaseContrastSet,
    varianceSet,
    activeTab,
    aiActivated
  ) => {
    activeButtons = props.isLoading;
    props.setActiveGeometric(selectedGeo);
    if (subpart) {
      props.setSubpart(subpart);
      props.setGeoName(subpart);
    } else {
      props.setSubpart(null);
      props.setGeoName(selectedGeo);
    }

    for (let layerName of [...Object.keys(props.settings.layers)]) {
      let buttonName = layerName + "Btn";
      activeButtons = {
        ...activeButtons,
        [layerName]: false,
        [buttonName]: true,
      };
    }

    if (reload) {
      geometrics = {};
    } else {
      geometrics = props.geometrics;
    }

    let layersToLoad = [];

    let smi = activeTab === "moisture";

    if (smi) layersToLoad = ["smi"];
    else {
      //The exception is a fallback for the old system!
      try {
        let layersToRender = [];
        if (subpart == null) {
          layersToRender = props.polygons[selectedGeo].LayersToRender;
        } else {
          layersToRender =
            props.polygons[selectedGeo].Subparts[subpart].LayersToRender;
        }

        layersToLoad = layersToRender.sort();
      } catch (e) {
        if (selectedGeo.toUpperCase().includes("HAZARD")) {
          layersToLoad = ["wqi"];
        } else {
          layersToLoad = ["evi", "gndvi", "ndvi", "rgb"];
        }
      }
    }

    //Adding special layers to the layers to load
    let specialLayersAvailable = false;
    if (subpart === null && !smi) {
      for (let sl in props.polygons[selectedGeo].SpecialLayers) {
        layersToLoad.push(sl);
        specialLayersAvailable = true;
      }
    }
    props.setIsLoading(activeButtons);

    /**
     * Since we use a object that stores all data in the state we check, if we already
     * got the data. We need to make the distinction if we are looking at a subpart, but
     * in both cases we are looking at the same geometric
     */
    let missingGeo = "";

    if (reload) missingGeo = selectedGeo;
    else {
      try {
        for (let layer of layersToLoad) {
          if (subpart !== null) {
            if (range && layer.toLowerCase() != "rgb") {
              if (
                !(
                  "diff_" + layer in
                    props.geometrics[selectedGeo].subparts[subpart] &&
                  "median_" + layer in
                    props.geometrics[selectedGeo].subparts[subpart]
                )
              ) {
                missingGeo = selectedGeo;
                break;
              }
            } else if (
              !(layer in props.geometrics[selectedGeo].subparts[subpart])
            ) {
              missingGeo = selectedGeo;
              break;
            }
          } else if (!(layer in props.geometrics[selectedGeo])) {
            missingGeo = selectedGeo;
            break;
          }
        }
      } catch (e) {
        missingGeo = selectedGeo;
      }
    }

    let layerNotAvailable = true;
    if (!reload) {
      for (let layer of layersToLoad) {
        if (props.activeLayer == layer) {
          layerNotAvailable = false;
          break;
        }
      }
    }

    let basemap = props.basemap;
    let image_collection = "image_collection";
    if (activeTab !== "spectral") image_collection += "_" + activeTab;
    if (aiActivated) image_collection += "_ai";

    if (!image_collection in props.settings) {
      throw new Error("Image collection not found.");
    }

    let dateReset =
      initialize |
      (props.tabActive !== activeTab) |
      (aiActivated && !props.aiActivated) |
      (!aiActivated && props.aiActivated);

    for (let layerName of layersToLoad) {
      let error = false;

      console.time(layerName);
      /**
       * If we found missing geometrics we make a call to the backend and await the
       * data. I've been using a local variable instead of the state because of the
       * delay to update the state.
       */
      if (missingGeo != "") {
        await fetch(GEE_URL + "/layer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dates: dateReset
              ? { startDate: "", endDate: "" }
              : {
                  startDate: dates.startDate.getTime(),
                  endDate: dates.endDate.getTime(),
                },
            isSpecialLayer: !(layerName in props.settings.layers),
            subpart: subpart,
            geometrics: props.polygons,
            layers: [layerName],
            selectedGeos: [missingGeo],
            isMedianOrDiff: layerName !== "rgb" ? range : false,
            increaseContrast: increaseContrastSet,
            bicubic: bicubicSet,
            variance: varianceSet,
            settings: props.settings,
            smi,
            image_collection,
            initial: Object.keys(availableDates).length === 0,
            ai: aiActivated,
          }),
        })
          .then((response) => response.text())
          .then((data) => {
            let formattedData;
            try {
              formattedData = JSON.parse(data);
              console.log(formattedData);
            } catch (e) {
              throw new Error(
                "Es gab einen Fehler, bitte versuchen Sie es erneut."
              );
            }

            try {
              let dates = {};
              if (Object.keys(availableDates).length == 0) {
                for (let image_collection_name of Object.keys(
                  formattedData.availableDates
                )) {
                  let temp_dates = [];
                  for (let date of formattedData.availableDates[
                    image_collection_name
                  ]) {
                    temp_dates.push(new Date(date));
                  }
                  dates[image_collection_name] = temp_dates;
                }
                setAvailableDates(dates);
              } else dates = availableDates;
              if (dateReset) {
                let newest_date = dates[image_collection]
                  .slice(-1)[0]
                  .getTime();
                let startDate = new Date(newest_date);
                let endDate = new Date(newest_date);
                startDate.setHours(0, 0, 0);
                endDate.setHours(23, 59, 59);
                setCurrentDates([startDate, endDate]);
              }

              if (!(missingGeo in geometrics)) {
                geometrics[missingGeo] = { subparts: {} };
              }
              if (subpart == null && "mapLayers" in formattedData) {
                geometrics[missingGeo] = {
                  ...geometrics[missingGeo],
                  ...formattedData.mapLayers[missingGeo],
                };
              } else if ("mapSubpartLayers" in formattedData) {
                if (subpart in geometrics[missingGeo].subparts)
                  geometrics[missingGeo].subparts[subpart] = {
                    ...geometrics[missingGeo].subparts[subpart],
                    ...formattedData.mapSubpartLayers[missingGeo][subpart],
                  };
                else
                  geometrics[missingGeo].subparts[subpart] =
                    formattedData.mapSubpartLayers[missingGeo][subpart];
              }

              basemap = formattedData.basemap;
            } catch (e) {
              console.log(e);
              if (layerName in props.settings.layers)
                throw new Error("Es gibt keine verfügbaren Daten.");
              else {
                error = true;
                specialLayersAvailable = false;
              }
            }
          });
      }

      if (!error) {
        if (!reload)
          geometrics = {
            ...props.geometrics,
            ...geometrics,
          };
        props.setGeometrics(geometrics);

        let buttonName = layerName + "Btn";
        activeButtons = {
          ...activeButtons,
          [layerName]: true,
          [buttonName]: false,
        };
        props.setIsLoading(activeButtons);

        toast(
          <>
            {layerName.toUpperCase()}
            <Translate value="sidepanel.loaded" />
          </>,
          {
            className: "custom-toast",
            toastId: layerName.toLowerCase() + "-toast",
            draggable: true,
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: false,
          }
        );

        if (
          (props.activeLayer === layerName) |
          (layerNotAvailable && layerName == layersToLoad[0])
        ) {
          props.showEEImage(
            initialize,
            layerName,
            selectedGeo,
            geometrics,
            subpart !== null,
            subpart,
            undefined,
            basemap
          );
          layerNotAvailable = false;
        }

        props.setBaseMap(basemap);
      }

      console.timeEnd(layerName);
    }

    setShowSpecialLayerButtons(specialLayersAvailable);
  };

  let renderSpecialLayerButtons = function () {
    let specialLayerButtons = [];
    if (props.geoName in props.polygons)
      for (let spl in props.polygons[props.geoName].SpecialLayers) {
        specialLayerButtons.push(
          <button
            className="btn"
            style={isSpecialLayerSelected ? { color: "#D3D3D3" } : {}}
            onClick={() => {
              if (isSpecialLayerSelected) {
                let temp = props.activeSpecialLayers;
                delete temp[spl];
                props.showEEImage(
                  initialize,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  temp
                );
              } else {
                props.showEEImage(initialize, spl);
              }
              setIsSpecialLayerSelected(!isSpecialLayerSelected);
            }}
          >
            {spl}
          </button>
        );
      }
    return specialLayerButtons;
  };

  /**
   * Gets chart data from the node express server, formats it and saves it in the react state
   *
   * @param geo name of the geo gotten from the dropdown menu or the current geo
   * @author Philip Sada
   */
  const getChartData = async (
    selectedGeo,
    subpart = null,
    reload = false,
    dates,
    range,
    activeTab,
    aiActivated
  ) => {
    //Exclude geometrics that are already in the state
    let missingChartData = [];

    if (reload) missingChartData.push(selectedGeo);
    else {
      try {
        if (subpart == null) {
          if (
            !("yAxis" in props.chartData[selectedGeo]) &&
            !("xAxis" in props.chartData[selectedGeo])
          ) {
            missingChartData.push(selectedGeo);
          }
        } else if (!(subpart in props.chartData[selectedGeo].subparts)) {
          missingChartData.push(selectedGeo);
        }
      } catch (e) {
        missingChartData.push(selectedGeo);
      }
    }

    props.setChartLoading(true);
    if (reload) {
      chartData = {};
    } else {
      chartData = props.chartData;
    }

    let charts = { ...props.charts };
    if (activeTab === "moisture") {
      if (aiActivated && "smi_ai" in charts) charts = { smi_ai: charts.smi_ai };
      else charts = { smi: charts.smi };
    } else {
      delete charts.smi;
    }

    let image_collection = "image_collection";
    if (activeTab !== "spectral") image_collection += "_" + activeTab;
    if (aiActivated) image_collection += "_ai";

    if (missingChartData.length > 0) {
      console.time("Charts");
      console.log("Test");
      await fetch(GEE_URL + "/charts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Origin": "test.karuna.technology"
        },
        mode: "cors",
        body: JSON.stringify({
          dates: dates,
          charts,
          layer: "charts",
          selectedGeos: missingChartData,
          geometrics: props.polygons,
          settings: props.settings,
          subpart: subpart,
          range: range,
          image_collection,
        }),
      })
        .then((response) => response.text())
        .then((data) => {
          const formattedData = JSON.parse(data);

          //format the given data correctly for the yAxis
          let formattedYAxisData = {};

          for (let geoName in formattedData) {
            let label = "";
            if (geoName.toLowerCase().includes("all")) {
              label = translate("sidepanel.all");
              if (geoName.endsWith("_all"))
                label += translate("sidepanel.holes");
              else label += geoName.substring(9);
            } else if (geoName.match(/^[0-9]$/)) {
              label = translate("sidepanel.hole") + " " + geoName.substring(5);
            } else {
              label = geoName;
            }

            let tempMin = {};
            let tempMax = {};
            let tempAvg = {};
            for (let yAxis of formattedData[geoName].yAxis) {
              let colors = [];
              let bandToLower = yAxis.band.toLowerCase();

              let tMin = 0.0;
              let tMax = 0.0;
              let tAvg = 0.0;
              let minimum, maximum;
              if (
                "max" in props.settings.layers[bandToLower] &&
                "min" in props.settings.layers[bandToLower]
              ) {
                minimum = Number.parseFloat(
                  props.settings.layers[bandToLower].min
                );
                maximum = Number.parseFloat(
                  props.settings.layers[bandToLower].max
                );
              } else {
                minimum = 0.3;
                maximum = 0.85;
              }
              for (let datum of yAxis.values[0].values) {
                if ((datum < tMin) | (tMin === 0.0)) tMin = datum;
                else if ((datum > tMax) | (tMax === 0.0)) tMax = datum;
                tAvg += datum;
                if (datum < minimum) {
                  colors.push("rgba(233, 75, 7, 1)");
                } else if (datum < maximum) {
                  colors.push("rgba(255, 180, 66, 1)");
                } else {
                  colors.push("rgba(79, 250, 5, 1)");
                }
              }
              tAvg = (tAvg / yAxis.values[0].values.length).toFixed(2);
              const getColor = function (value) {
                let grey = "rgba(224, 224, 235, 1)";
                if (value < minimum) {
                  return {
                    green: grey,
                    orange: grey,
                    red: "rgba(233, 75, 7, 1)",
                    current: "red",
                  };
                } else if (value < maximum) {
                  return {
                    green: grey,
                    orange: "rgba(255, 180, 66, 1)",
                    red: grey,
                    current: "orange",
                  };
                } else {
                  return {
                    green: "rgba(73, 222, 23, 1)",
                    orange: grey,
                    red: grey,
                    current: "green",
                  };
                }
              };

              tempMin[yAxis.band] = { value: tMin, color: getColor(tMin) };
              tempMax[yAxis.band] = { value: tMax, color: getColor(tMax) };
              tempAvg[yAxis.band] = { value: tAvg, color: getColor(tAvg) };

              colors = colors.slice(
                yAxis.values[0].values.length -
                  formattedData[geoName].xAxis.length
              );

              formattedYAxisData = {
                ...formattedYAxisData,
                [yAxis.band]: {
                  label: label,
                  data: yAxis.values[0].values.slice(
                    yAxis.values[0].values.length -
                      formattedData[geoName].xAxis.length
                  ),
                  fill: true,
                  borderWidth: 0.5,
                  backgroundColor: colors,
                  borderColor: colors,
                  order: 1,
                  yAxisID: "y",
                },
              };
            }

            setMin(tempMin);
            setMax(tempMax);
            setAvg(tempAvg);

            if (subpart == null) {
              chartData = {
                ...chartData,
                [geoName]: {
                  ...chartData[geoName],
                  yAxis: formattedYAxisData,
                  xAxis: formattedData[geoName].xAxis.reverse(),
                },
              };
            } else {
              try {
                chartData = {
                  ...chartData,
                  [selectedGeo]: {
                    ...chartData[selectedGeo],
                    subparts: {
                      ...chartData[selectedGeo].subparts,
                      [subpart]: {
                        yAxis: formattedYAxisData,
                        xAxis: formattedData[geoName].xAxis.reverse(),
                      },
                    },
                  },
                };
              } catch (e) {
                chartData = {
                  ...chartData,
                  [selectedGeo]: {
                    ...chartData[selectedGeo],
                    subparts: {
                      [subpart]: {
                        yAxis: formattedYAxisData,
                        xAxis: formattedData[geoName].xAxis.reverse(),
                      },
                    },
                  },
                };
              }
            }
          }

          if (Object.keys(chartData).length > 0) {
            props.setChartData(chartData);
          } else {
            throw new Error("No available chart data for the selected dates.");
          }

          console.timeEnd("Charts");
        });
    }

    /**
      when this point is reached we assume all necessary data is in chartData
      If everything we need is in the state the call won't be triggered, therefore
      we need to make sure that all the data is in the variable by including the state
      and the values that might have been set in the call
    */
    chartData = {
      ...props.chartData,
      ...chartData,
    };
    activeChartData = {};
    if (subpart != null) {
      activeChartData = {
        ...chartData[selectedGeo].subparts[subpart],
      };
    } else {
      activeChartData = {
        ...activeChartData,
        ...chartData[selectedGeo],
      };
    }

    toast(<Translate value="sidepanel.generated_charts" />, {
      className: "custom-toast",
      toastId: "chart-toast",
      draggable: true,
      position: toast.POSITION.BOTTOM_RIGHT,
      hideProgressBar: false,
    });
    props.setActiveChartData(activeChartData);
    props.setChartLoading(false);
  };

  /**
   * Calls the functions needed to get data from the node express server,
   *
   * @param geo name of the geo gotten from the dropdown menu
   * @author Philip Sada
   */
  const getGeoData = async (
    geo = props.activeGeometric,
    subpart = null,
    reload = false,
    dates = { startDate: currentDates[0], endDate: currentDates[1] },
    range = rangeSelected,
    bicubicSet = props.bicubic,
    increaseContrastSet = props.increaseContrast,
    varianceSet = props.variance,
    chartsActivated = props.chartsActivated,
    activeTab = props.tabActive,
    aiActivated = props.aiActivated
  ) => {
    /**
     * When this point is reached the geometric is changed, so we discard
     * all active data and set everything to the new geometric
     */
    clearData();

    /**
     * This is the part where the data is actually requested, the button is disabled, because
     * the parallel execution of the functions messes up the state. If an error occurs all data
     * is reset. The already gathered data in the chartData and geometrics is kept, because
     * the data might be useful and if incomplete will be handled in the getData/getChartData function
     */
    setIsLoading(true);
    try {
      await getData(
        geo,
        subpart,
        reload,
        dates,
        range,
        bicubicSet,
        increaseContrastSet,
        varianceSet,
        activeTab,
        aiActivated
      );
      if (chartsActivated)
        await getChartData(
          geo,
          subpart,
          reload,
          Object.keys(availableDates).length === 0
            ? { startDate: null, endDate: null }
            : dates,
          range,
          activeTab,
          aiActivated
        );
    } catch (e) {
      setError(e.message);
      props.setActiveSpecialLayers({});
      props.setActiveGeometric("");
      props.setActiveChartData({});
      props.setChartLoading(false);

      for (let layerName of Object.keys(props.settings.layers)) {
        let buttonName = layerName + "Btn";
        activeButtons = {
          ...activeButtons,
          [layerName]: false,
          [buttonName]: false,
        };
      }
      for (let specialLayerName of Object.keys(
        props.settings.special_layers.layers
      )) {
        let buttonName = specialLayerName.clear_name + "Btn";
        activeButtons = {
          ...activeButtons,
          [specialLayerName.clear_name]: false,
          [buttonName]: false,
        };
      }
      props.setIsLoading(activeButtons);
      props.setGeoName(translate("sidepanel.choose"));
    }
    setIsLoading(false);
  };

  /**
   * Collects the start and end dates from the date picker and
   * runs the functions needed to get updated gee data from the node express server
   *
   * @author Philip Sada
   *
   * @param start this is selected start date coming from the date picker
   * @param end this is selected end date coming from the date picker
   *
   */
  const filterGEEScripts = async (
    dates,
    range = undefined,
    activeTab = props.tabActive,
    ai = false
  ) => {
    if (props.geoName !== translate("sidepanel.choose")) {
      await getGeoData(
        props.activeGeometric,
        props.subpart,
        true,
        dates,
        range,
        undefined,
        undefined,
        undefined,
        undefined,
        activeTab,
        ai
      );
    } else {
      setError("Bitte wählen Sie ein gültiges Element.");
    }
  };

  const clearData = function () {
    //clear existing layers, chart data, dates and selected geos
    setShow(false);
    setError("");
    props.setActiveGeometric("");
    props.setGeometrics({});
    props.setActiveChartData({});
    props.setChartData({});
    props.setActiveSpecialLayers({});
    props.resetMap();
    setIsSpecialLayerSelected(false);
    setShowSpecialLayerButtons(false);
  };

  const handleDates = async (
    value,
    activeTab = props.tabActive,
    ai = props.aiActivated
  ) => {
    let startDate = new Date(value[0].getTime());
    let endDate = new Date(value[1].getTime());

    endDate.setHours(23, 59, 59);
    startDate.setHours(0, 0, 0);
    setCurrentDates([startDate, endDate]);

    let range = false;
    if (endDate - startDate >= 86400000) {
      range = true;
    }
    setRangeSelected(range);

    await filterGEEScripts({ startDate, endDate }, range, activeTab, ai);
  };

  let geoButtons = [];
  for (let part in props.polygons) {
    //Add the overview button and then all the available subpart buttons
    let subButtons = [];
    let all = part == "All";
    if ("Polygons" in props.polygons[part])
      subButtons.push(
        <Dropdown.Item
          onClick={() => {
            getGeoData(part);
            setSubpartDropdownValue(<Translate value="sidepanel.overview" />);
          }}
        >
          <Translate value="sidepanel.overview" />
        </Dropdown.Item>
      );
    Object.keys(props.polygons[part].Subparts).map((subpart) =>
      subButtons.push(
        <Dropdown.Item
          onClick={() => {
            getGeoData(part, subpart);
            setSubpartDropdownValue(subpart);
          }}
        >
          {subpart}
        </Dropdown.Item>
      )
    );

    //Add the main buttons
    let translatedName = part;
    if (part.startsWith("Loch") || part.startsWith("Hole")) {
      translatedName = (
        <>
          <Translate value="sidepanel.hole" />
          {part.substring(5)}
        </>
      );
    } else if (all) {
      translatedName = <Translate value="sidepanel.show_all" />;
    }
    geoButtons.push(
      <Dropdown.Item
        onClick={() => {
          if (subButtons.length == 1) {
            if (!all) {
              getGeoData(part);
              setSubpartDropdownValue(<Translate value="sidepanel.overview" />);
            } else {
              getGeoData(part, Object.keys(props.polygons[part].Subparts)[0]);
              setSubpartDropdownValue(<Translate value="sidepanel.overview" />);
            }
          } else {
            setSubpartDropdownValue(<Translate value="sidepanel.choose" />);
            props.setSubpart(null);
          }
          setSubButtons(subButtons);
          setGeoDropdownValue(translatedName);
        }}
      >
        {translatedName}
      </Dropdown.Item>
    );
  }

  let renderLayers = function () {
    layerButtons = [];

    let currentLayers;

    if (props.tabActive === "moisture") currentLayers = ["smi"];
    else
      try {
        if (props.subpart == null) {
          currentLayers =
            props.geometrics[props.activeGeometric].LayersToRender;
        } else
          currentLayers =
            props.polygons[props.activeGeometric].Subparts[props.subpart]
              .LayersToRender;
      } catch (e) {}

    //Fallback for older aoi-versions!
    if (currentLayers == undefined) {
      currentLayers = props.activeGeometric.toUpperCase().includes("HAZARD")
        ? ["wqi"]
        : ["evi", "gndvi", "ndvi", "rgb"];
    }

    for (let layerName of currentLayers.sort()) {
      let buttonName = layerName + "Btn";
      let isLayerLoaded = props.isLoading[layerName];
      let isButtonLoading = props.isLoading[buttonName];
      let rdAvailable = false;

      if (rangeSelected && layerName.toUpperCase() != "RGB") {
        addButton(
          isLayerLoaded,
          isButtonLoading,
          layerName.toUpperCase(),
          layerName,
          true
        );
        rdAvailable = true;
      }
      if (!rdAvailable) {
        addButton(
          isLayerLoaded,
          isButtonLoading,
          layerName.toUpperCase(),
          layerName
        );
      }
    }
    return layerButtons;
  };

  const downloadGraphAsCSV = () => {
    let graphData = ["," + props.activeChartData.xAxis.join(", ")];
    for (let band in props.activeChartData.yAxis) {
      graphData.push(
        band + ", " + props.activeChartData.yAxis[band].data.join(", ")
      );
    }

    const link = document.createElement("a");
    link.download = "data.csv";
    link.href = window.URL.createObjectURL(
      new Blob([graphData.join("\n")], { type: "text/csv" })
    );

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };

  let addButton = function (
    isLayerLoaded,
    isButtonLoading,
    clearName,
    layerName,
    medianOrDiff = false
  ) {
    if (!isLayerLoaded) {
      if (medianOrDiff) {
        layerButtons.push(
          <div class="btn-group">
            <DropdownButton
              title={
                isButtonLoading ? (
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>{clearName}</span>
                )
              }
              disabled
            >
              <Dropdown.Item as="button" className="standard-button">
                <Translate value="sidepanel.diff" />
              </Dropdown.Item>
              <Dropdown.Item as="button" className="standard-button">
                <Translate value="sidepanel.median" />
              </Dropdown.Item>
            </DropdownButton>
          </div>
        );
      } else {
        layerButtons.push(
          <button className="btn" disabled>
            {isButtonLoading ? (
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>{clearName}</span>
            )}
          </button>
        );
      }
    } else if (medianOrDiff) {
      layerButtons.push(
        <div class="btn-group">
          <DropdownButton title={clearName}>
            <Dropdown.Item
              as="button"
              className="standard-button"
              onClick={() => props.showEEImage(initialize, "diff_" + layerName)}
            >
              <Translate value="sidepanel.diff" />
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              className="standard-button"
              onClick={() =>
                props.showEEImage(initialize, "median_" + layerName)
              }
            >
              <Translate value="sidepanel.median" />
            </Dropdown.Item>
          </DropdownButton>
        </div>
      );
    } else {
      layerButtons.push(
        <button
          className="btn"
          onClick={() => props.showEEImage(initialize, layerName)}
        >
          {clearName}
        </button>
      );
    }
  };

  let startView = function () {
    let firstPolygon = Object.keys(props.polygons)[0];
    if ("Polygons" in props.polygons[firstPolygon]) {
      getGeoData(firstPolygon);
    } else {
      let subparts = Object.keys(props.polygons[firstPolygon].Subparts);
      getGeoData(firstPolygon, subparts[0]);
    }
    if (firstPolygon.includes("All"))
      firstPolygon = <Translate value="sidepanel.show_all" />;
    setGeoDropdownValue(firstPolygon);
    setSubButtons(null);
    setInitialize(false);
    return null;
  };

  let handleBicubicChange = async (e) => {
    props.setBicubic(e.target.checked);
    props.updateUser(e.target.checked);
    clearData();
    await getGeoData(
      undefined,
      props.subpart,
      true,
      undefined,
      undefined,
      e.target.checked
    );
  };

  let handleIncreaseContrast = async (e) => {
    props.setIncreaseContrast(e.target.checked);
    props.setVariance(false);
    props.updateUser(undefined, e.target.checked);
    clearData();
    await getGeoData(
      undefined,
      props.subpart,
      true,
      undefined,
      undefined,
      undefined,
      e.target.checked,
      false
    );
  };

  let handleVariance = async (e) => {
    props.setVariance(e.target.checked);
    props.setIncreaseContrast(false);
    props.updateUser(undefined, undefined, e.target.checked);
    clearData();
    await getGeoData(
      undefined,
      props.subpart,
      true,
      undefined,
      undefined,
      undefined,
      false,
      e.target.checked
    );
  };

  let handleChartChange = async (e) => {
    props.setChartsActivated(e.target.checked);
    await getGeoData(
      undefined,
      props.subpart,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      e.target.checked
    );
  };

  let handleAi = async (e) => {
    props.setAiActivated(e.target.checked);
    updateView("moisture", e.target.checked);
    // await getGeoData(
    //   undefined,
    //   props.subpart,
    //   true,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   undefined,
    //   e.target.checked
    // );
  };

  let renderDatePicker = function () {
    return (
      <div className="mt-2 mb-2 ml-2 mr-2 d-flex flex-column align-items-center">
        {error !== "" && (
          <div className="alert alert-danger" role="alert">
            <span className="">{error}</span>
          </div>
        )}
        <DateRangePicker
          intro-tour="reactour__datepicker"
          cleanable={false}
          ranges={[]}
          disabledDate={(date, selectDate, selectedDone) => {
            if (selectDate[0] < date && selectDate[1] > date) {
              return false;
            }
            let image_collection = "image_collection";
            if (props.tabActive !== "spectral")
              image_collection += "_" + props.tabActive;
            if (props.aiActivated) image_collection += "_ai";
            for (let availableDate of availableDates[image_collection]) {
              if (
                date.getFullYear() == availableDate.getFullYear() &&
                date.getMonth() == availableDate.getMonth() &&
                date.getDate() == availableDate.getDate()
              ) {
                return false;
              }
            }
            return true;
          }}
          disabled={isLoading}
          onOk={(value) => handleDates(value)}
          onChange={(value) => setCurrentDates(value)}
          placeholder="TT-MM-JJJJ"
          value={currentDates}
          format="dd-MM-yyyy"
        />
      </div>
    );
  };

  let updateView = function (e, ai = props.aiActivated) {
    let smi = e === "moisture";
    let image_collection = "image_collection";
    if (e !== "spectral") image_collection += "_" + e;
    if (ai) image_collection += "_ai";
    let dates = availableDates[image_collection];
    props.updateUser(undefined, undefined, undefined, smi);
    let date = dates[dates.length - 1];

    handleDates([date, date], e, ai);
    props.setTabActive(e);
    setSubButtons(null);
  };

  if (props.dataLoaded) {
    return (
      <>
        <div
          className={
            sidePanelOpened
              ? "side-panel-container active"
              : "side-panel-container"
          }
        >
          <div id="geometric-dropdown" className="mt-2 mb-2 ml-2 mr-2">
            <p>
              <Translate value="sidepanel.visualization_text" />
            </p>
            <ButtonGroup intro-tour="reactour__geopicker" className="mt-2">
              <DropdownButton
                id="dropdown-geo-button"
                title={geoDropdownValue}
                // className="mt-2"
                disabled={isLoading}
                position="right"
              >
                {geoButtons}
              </DropdownButton>
              {subButtons !== null ? (
                <DropdownButton
                  // className="mt-2"
                  id="dropdown-sub-button"
                  title={subpartDropdownValue}
                  disabled={isLoading}
                  position="right"
                >
                  {subButtons}
                </DropdownButton>
              ) : null}
            </ButtonGroup>
          </div>

          <div id="map-control" className="mt-3 mb-3 ml-4 mr-4">
            <Form>
              <Form.Check
                type="switch"
                id="charts-switch"
                label={<Translate value="sidepanel.charts_activated" />}
                disabled={isLoading}
                checked={props.chartsActivated}
                onChange={(e) => handleChartChange(e)}
                intro-tour="reactour__chart"
              />
              <Form.Check
                type="switch"
                id="bicubic-switch"
                label={<Translate value="sidepanel.bicubic" />}
                disabled={isLoading}
                checked={props.bicubic}
                onChange={(e) => handleBicubicChange(e)}
                intro-tour="reactour__bicubic"
              />
              <Form.Check
                type="switch"
                id="increase-contrast-switch"
                label={<Translate value="sidepanel.contrast" />}
                disabled={isLoading}
                checked={props.increaseContrast}
                onChange={(e) => handleIncreaseContrast(e)}
                intro-tour="reactour__increase_contrast"
              />
              <Form.Check
                type="switch"
                id="variance-switch"
                label={<Translate value="sidepanel.variance" />}
                disabled={isLoading}
                checked={props.variance}
                onChange={(e) => handleVariance(e)}
                intro-tour="reactour__variance"
              />
              {(props.tabActive !== "spectral"
                ? "image_collection_" + props.tabActive + "_ai"
                : "image_collection_ai") in props.settings.general &&
                props.aiEnabled && (
                  <Form.Check
                    type="switch"
                    id="ai-switch"
                    label={<Translate value="sidepanel.ai" />}
                    disabled={isLoading}
                    checked={props.aiActivated}
                    onChange={(e) => handleAi(e)}
                    intro-tour="reactour__ai"
                  />
                )}
            </Form>
            {props.activeLayer !== "" ? (
              <>
                <button
                  id="reset-map-button"
                  className="standard-button btn"
                  onClick={() => props.resetMap()}
                  disabled={isLoading}
                  intro-tour="reactour__reset"
                >
                  <RiCloseFill className="grid-view-icon" />
                  {<Translate value="sidepanel.clear_map" />}
                </button>
              </>
            ) : null}
          </div>

          <button className="toggle-side-panel-btn" onClick={toggleSidePanel}>
            {sidePanelOpened ? (
              <AiOutlineCaretLeft className="caret-icon" />
            ) : (
              <AiOutlineCaretRight className="caret-icon" />
            )}
          </button>
          <div className="visualization-container">
            <Tabs
              id="tab"
              activeKey={props.tabActive}
              className="mb-3"
              fill
              justify
              onSelect={(e) => updateView(e)}
            >
              <Tab
                eventKey="spectral"
                title={translate("sidepanel.spectral")}
                disabled={isLoading}
              >
                {renderDatePicker()}
                <div
                  className="d-flex p-3 mb-3 justify-content-center align-items-center border-bottom"
                  intro-tour="reactour__layers"
                >
                  {Object.keys(props.settings.layers).length !== 0
                    ? renderLayers()
                    : null}
                </div>
              </Tab>
              <Tab
                eventKey="moisture"
                title={translate("sidepanel.moisture")}
                disabled={!props.moistureActive | isLoading}
              >
                {renderDatePicker()}
                <div
                  className="d-flex pt-3 justify-content-center align-items-center"
                  intro-tour="reactour__layers"
                >
                  {Object.keys(props.settings.layers).length !== 0
                    ? renderLayers()
                    : null}
                </div>
              </Tab>
            </Tabs>

            {showSpecialLayerButtons ? (
              <div className="card visualisierung-card p-2">
                <p>
                  <Translate value="sidepanel.available_data" />
                </p>
                <div className="d-flex pt-3 justify-content-center align-items-center">
                  {renderSpecialLayerButtons()}
                </div>
              </div>
            ) : null}

            {props.activeGeo in props.chartData && !props.chartLoading ? (
              <div className="d-flex justify-content-end mt-3 mb-3 mr-2">
                <RiLayoutGridFill
                  onClick={handleShow}
                  className="grid-view-icon"
                />
              </div>
            ) : (
              <div></div>
            )}

            {props.chartsActivated ? (
              <div>
                <div intro-tour="reactour__charts">
                  <div>
                    <div className="d-flex justify-content-end mt-3 mb-3 mr-2">
                      {props.isAdmin && (
                        <>
                          <RiExchangeLine
                            className="grid-view-icon"
                            onClick={
                              isLoading ? null : () => setShowAbs(!showAbs)
                            }
                          />
                          <RiFileDownloadLine
                            onClick={isLoading ? null : downloadGraphAsCSV}
                            className="grid-view-icon"
                          />
                        </>
                      )}
                      <RiLayoutGridFill
                        onClick={isLoading ? null : handleShow}
                        className="grid-view-icon"
                        intro-tour="reactour__magnify_charts"
                      />
                    </div>
                    {props.chartLoading ? (
                      <div className="mt-5 d-flex justify-content-center align-items-center flex-column">
                        <div className="mt-5">
                          <LoadingDots />
                        </div>
                        <p className="mt-1 font-weight-bold">
                          <Translate value="sidepanel.generating_charts" />
                        </p>
                      </div>
                    ) : "yAxis" in props.activeChartData ? (
                      <Accordion alwaysOpen>
                        {Object.keys(props.activeChartData.yAxis).map(
                          (band, index) => (
                            <>
                              <div key={index}>
                                <Accordion.Item eventKey={index}>
                                  <Accordion.Header className="side-panel-card-header">
                                    <div class="d-inline-flex align-items-center">
                                      <div style={{ width: "4vh" }}>{band}</div>
                                      {band in min &&
                                        band in max &&
                                        band in avg && (
                                          <div class="d-inline-flex position-absolute traffic-lights">
                                            <div
                                              class="circle"
                                              style={{
                                                backgroundColor:
                                                  avg[band].color.green,
                                              }}
                                            />
                                            <div
                                              class="circle"
                                              style={{
                                                backgroundColor:
                                                  avg[band].color.orange,
                                              }}
                                            />
                                            <div
                                              class="circle"
                                              style={{
                                                backgroundColor:
                                                  avg[band].color.red,
                                              }}
                                            />
                                          </div>
                                        )}
                                    </div>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <Card>
                                      <Card.Body className="card-body">
                                        {band in min &&
                                          band in max &&
                                          band in avg && (
                                            <>
                                              <div className="d-flex border justify-content-evenly mt-3 mb-3 mr-2">
                                                <b>Min:</b>
                                                <p
                                                  style={{
                                                    color:
                                                      min[band].color[
                                                        min[band].color.current
                                                      ],
                                                  }}
                                                >
                                                  {min[band].value}
                                                </p>
                                                <b>Max:</b>
                                                <p
                                                  style={{
                                                    color:
                                                      max[band].color[
                                                        max[band].color.current
                                                      ],
                                                  }}
                                                >
                                                  {max[band].value}
                                                </p>
                                                <b>Avg:</b>
                                                <p
                                                  style={{
                                                    color:
                                                      avg[band].color[
                                                        avg[band].color.current
                                                      ],
                                                  }}
                                                >
                                                  {avg[band].value}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        <Chart
                                          xAxis={props.activeChartData.xAxis}
                                          yAxis={[
                                            props.activeChartData.yAxis[band],
                                          ]}
                                          settings={props.settings}
                                          band={band}
                                          isMedian={rangeSelected}
                                          showAbs={showAbs}
                                        />
                                      </Card.Body>
                                    </Card>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </div>
                            </>
                          )
                        )}
                      </Accordion>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div>
          {show && (
            <Modal
              animation={false}
              show={show}
              size="xl"
              onHide={handleClose}
              centered
            >
              <Modal.Body className="grid-view-modal-body">
                <div className="row">
                  {Object.keys(props.activeChartData.yAxis).map(
                    (band, index) => (
                      <div className="col-md-6" key={index}>
                        <p className="pt-2 font-weight-bold"> {band}</p>
                        <Chart
                          xAxis={props.activeChartData.xAxis}
                          yAxis={[props.activeChartData.yAxis[band]]}
                          settings={props.settings}
                          band={band}
                          isMedian={rangeSelected}
                          showAbs={showAbs}
                        />
                      </div>
                    )
                  )}
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
        {initialize ? startView() : null}
        <ToastContainer />
      </>
    );
  } else {
    return null;
  }
}
