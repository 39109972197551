import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Dashboard.scss";
import Table from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Dashboard from "../../../utils/Dashboard";
import Delete from "../../../utils/Delete";
import Upload from "../script/UploadScript";
import LoadingIcon from "../../../components/atoms/LoadingIcon";
import { Form } from "react-bootstrap";

export default class ScriptDashboard extends Dashboard {
  constructor(props) {
    super(props, {
      currentElem: null,
      showEditModal: false,
      showDeleteModal: false,
      showUpdateModal: false,
      scripts: [],
      currentPage: 1,
      contentPerPage: 6,
      content: [],
      loading: true,
      error: false,
      errorMessage: "",
      settings: {
        general: {
          center: { lng: "", lat: "", zoom: "" },
          bbox: "",
          image_collection: "",
          image_collection_moisture: "",
          reduce_region_options: "",
          filter_options: "",
          water_palette: [],
          basemap: { image_collection: "", opacity: 100 },
        },
        range: {
          min: 0.0,
          max: 0.0,
        },
        layers: {},
      },
    });
    this.setShowModal = this.setShowModal.bind(this);
    this.reload = this.reload.bind(this);
  }

  componentDidMount = async () => {
    await this.reload();
    for (let script of this.state.scripts) {
      for (let id in script) {
        if (script[id].main_script) {
          let settings = JSON.parse(script[id].settings);
          settings.general.center = { lat: "", lng: "", zoom: "" };
          settings.general.bbox = "";
          this.updateSettings(settings);
        }
      }
    }
  };

  reload = async () => {
    await this.load("scripts", "/admin/scripts/get");
    await this.load("aois", "/admin/aois");
  };

  /**
   * This function is called when a button on an element is pressed. All
   * the necessary data is set accordingly
   *
   * @author Hagen Hoppenstedt
   * @param type {string} The name of the modal that should be shown
   * @param elem {Object} The element data
   */
  setCurrentElement(type, elem) {
    this.setShowModal(type);
    this.setState({ currentElem: elem });
  }

  updateSettings(settings) {
    this.setState({ settings: { ...this.state.settings, ...settings } });
  }

  /**
   * Renders the content of the table
   *
   * @author Hagen Hoppenstedt
   */
  renderScriptData() {
    if (this.state.loading) {
      return LoadingIcon();
    } else {
      let scriptData = [];
      const { SearchBar } = Search;
      const columns = [
        {
          dataField: "index",
          text: "#",
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "alias",
          text: "Alias",
          sort: true,
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "aois",
          text: "Assigned AoI",
          sort: true,
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "membership",
          text: "Assigned Memberships",
          sort: true,
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "main_script",
          text: "Main script?",
          sort: true,
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "edit",
          text: "",
          style: { textAlign: "center" },
        },
        {
          dataField: "delete",
          text: "",
          style: { textAlign: "center" },
        },
      ];
      this.state.scripts.map((elem, index) => {
        let currentElem = Object.values(elem)[0];
        scriptData.push({
          index: index + 1,
          alias: currentElem.alias,
          main_script: (
            <>
              <Form.Check type={"checkbox"} checked={currentElem.main_script} />
            </>
          ),
          aois: currentElem.aois.join(", "),
          membership: currentElem.membership.join(", "),
          edit: (
            <button
              class="btn btn-dark btn-sm"
              onClick={() => this.setCurrentElement("Edit", currentElem)}
            >
              Edit
            </button>
          ),
          delete: (
            <button
              class="btn btn-danger btn-sm"
              onClick={() => this.setCurrentElement("Delete", currentElem)}
            >
              Delete
            </button>
          ),
        });
      });
      return (
        <>
          <ToolkitProvider
            keyField="id"
            data={scriptData}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <hr />
                <Table
                  {...props.baseProps}
                  pagination={paginationFactory({ sizePerPage: 10 })}
                />
              </div>
            )}
          </ToolkitProvider>
          <div>
            <button
              class="btn btn-light create"
              onClick={() => this.setShowModal("Upload")}
            >
              Upload Script
            </button>
          </div>
        </>
      );
    }
  }

  render() {
    let table = [];
    table.push(this.renderScriptData());
    table.push(
      this.state.showEditModal && (
        <Upload
          availableAois={this.state.aois}
          currentAois={this.state.currentElem.aois}
          currentMemberships={this.state.currentElem.membership}
          settings={this.state.settings}
          alias={this.state.currentElem.alias}
          id={this.state.currentElem.id}
          main_script={this.state.currentElem.main_script}
          edit={true}
          name={this.state.currentElem.alias}
          reload={this.reload}
          setShowModal={this.setShowModal}
          showModal={this.state.showEditModal}
        />
      )
    );
    table.push(
      this.state.showDeleteModal && (
        <Delete
          reload={this.reload}
          source="dashboard"
          type="Script"
          id={this.state.currentElem.id}
          name={this.state.currentElem.alias}
          setShowModal={this.setShowModal}
          showModal={this.state.showDeleteModal}
        />
      )
    );
    table.push(
      this.state.showUploadModal && (
        <Upload
          availableAois={this.state.aois}
          currentAois={[]}
          currentMemberships={[]}
          settings={this.state.settings}
          main_script={false}
          id={""}
          alias={""}
          edit={false}
          reload={this.reload}
          setShowModal={this.setShowModal}
          showModal={this.state.showUploadModal}
        />
      )
    );
    return super.render(table);
  }
}