import React, { Component } from "react";
import { Form, Row, Col, Alert } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { postApi } from "../../../services/api/requestApi";
import { DateRangePicker } from "rsuite";
import moment from "moment-timezone";
import "../script/Scripts.scss";

export default class Execute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = async () => {};

  handleClose = () => {
    this.props.setShowModal("Execute", false);
  };

  handleSubmit = async (dry_run = false, forcePPS = false, geoTiff=false) => {
    let url, body;
    let startDate = this.state.selectedDates[0];
    let endDate = this.state.selectedDates[1];
    if (this.props.currentElem.ai) {
      url = "/admin/icscripts/python";
      body = {
        id: this.props.currentElem.id,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      };
    } else {
      url = "/admin/icscripts/execute";
      body = {
        id: this.props.currentElem.id,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        dry_run: dry_run,
        force_PPS: forcePPS,
        geoTiff:geoTiff,
      };
    }
    postApi(url, body);
    this.handleClose();
    this.props.reload();
  };

  render() {
    return (
      <>
        <Modal
          size="lg"
          animation={false}
          show={this.props.showModal}
          onHide={this.handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Execute the script</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.error !== "" && (
              <Alert variant="danger" transition={false}>
                {this.state.error}
              </Alert>
            )}
            <Form>
              <Row className="mb-3">
                <Form.Group as={Col} className="4">
                  <DateRangePicker
                    size="lg"
                    ranges={[]}
                    onChange={(value) => {
                      this.setState({
                        selectedDates: [
                          new Date(
                            Date.UTC(
                              value[0].getFullYear(),
                              value[0].getMonth(),
                              value[0].getDate(),
                              0,
                              0,
                              0,
                              0
                            )
                          ),
                          new Date(
                            Date.UTC(
                              value[1].getFullYear(),
                              value[1].getMonth(),
                              value[1].getDate(),
                              23,
                              59,
                              59,
                              999
                            )
                          ),
                          ,
                        ],
                      });
                    }}
                  />
                </Form.Group>
                <Form.Text muted>
                  Select two dates between which you want to retrieve all images
                  from.
                </Form.Text>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div class="script-upload-footer">
              <button
                className="btn btn-light mr-3"
                type="button"
                onClick={() => this.handleSubmit(true)}
              >
                Dry-run
              </button>
              <button
                className="btn btn-light mr-3"
                type="button"
                onClick={() => this.handleSubmit(false, true)}
              >
                Execute (Force PPS)
              </button>
              <button
                className="btn btn-light mr-3"
                type="button"
                onClick={() => this.handleSubmit(false, false, true)}
              >
                Execute (geo Tiff)
              </button>
              <button
                className="btn btn-dark mr-3"
                type="button"
                onClick={() => this.handleSubmit()}
              >
                Execute
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
