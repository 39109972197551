//export const BASE_URL = "http://backend.karuna";
export const BASE_URL = "https://test.eo.backend.karuna.technology"
//export const BASE_URL = "https://greenway-backend.karuna.technology"

//export const GEE_URL = "http://localhost:8080";
export const GEE_URL = "https://re.karuna.technology"
//export const GEE_URL = "https://greenway-re.karuna.technology"

export const PROJECT_IDS = [
  "karuna-eo-test",
  "karuna-eo-aut1",
  "karuna-eo-aut2",
];

export const REGIONS = ["US", "Europe"];
